import React, {Component} from "react";
import postlist from "../albums.json"
import {Col} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

class Music extends Component {
    state = {};

    // constructor(props) {
    //     super(props);
    //     console.log("test")
    // }

    render() {
        return (
            <Row className={"content"}>
                {postlist.length > 0  &&
                postlist.map((post, i) => {
                    const imageTag = (<Image className={"album-image"} src={post.thumbnail} title={post.title}
                                             alt={post.altTag}/>)
                    if (post.link) {
                        return (
                            <Col key={post.title}>
                                <a href={post.link}>
                                    {imageTag}
                                </a>
                                <p>{post.caption}</p>
                            </Col>
                        )
                    } else {
                        return (
                            <Col key={post.title}>
                                {imageTag}
                                <p>{post.caption}</p>
                            </Col>
                        )
                    }

                })
                }
            </Row>
        );
    }
}

export default Music;
