import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import config from "../config.json"

class Merch extends Component {


    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let url = config['shopify_store_url'] ? config['shopify_store_url'] : ""
        let key = config['storefront_access_token'] ? config['storefront_access_token'] : ""
        let collection_id = config['shopify_collection_id'] ? config['shopify_collection_id'] : ""
        this.initShopify(url, key, collection_id)
    }

    initShopify(url, access_token, collection_id) {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }

        function loadScript() {
            var script = document.createElement('script');
            script.async = true;
            script.src = scriptURL;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
            script.onload = ShopifyBuyInit;
        }

        function ShopifyBuyInit() {
            // eslint-disable-next-line no-undef
            var client = ShopifyBuy.buildClient({
                domain: url,
                storefrontAccessToken: access_token,
            });
            // eslint-disable-next-line no-undef
            ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent('collection', {
                    id: collection_id,
                    node: document.getElementById('collection-component-1605391327262'),
                    moneyFormat: '%24%7B%7Bamount%7D%7D',
                    options: {
                        "product": {
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "calc(25% - 20px)",
                                        "margin-left": "20px",
                                        "margin-bottom": "50px",
                                        "width": "calc(25% - 20px)"
                                    },
                                    "img": {
                                        "height": "calc(100% - 15px)",
                                        "position": "absolute",
                                        "left": "0",
                                        "right": "0",
                                        "top": "0"
                                    },
                                    "imgWrapper": {
                                        "padding-top": "calc(75% + 15px)",
                                        "position": "relative",
                                        "height": "0"
                                    }
                                },
                                "button": {
                                    "font-family": "Times New Roman, serif",
                                    ":hover": {
                                        "background-color": "#000000"
                                    },
                                    "background-color": "#000000",
                                    ":focus": {
                                        "background-color": "#000000"
                                    },
                                    "padding-left": "73px",
                                    "padding-right": "73px"
                                }
                            },
                            "text": {
                                "button": "Add to cart"
                            }
                        },
                        "productSet": {
                            "styles": {
                                "products": {
                                    "@media (min-width: 601px)": {
                                        "margin-left": "-20px"
                                    }
                                }
                            }
                        },
                        "modalProduct": {
                            "contents": {
                                "img": false,
                                "imgWithCarousel": true,
                                "button": false,
                                "buttonWithQuantity": true
                            },
                            "styles": {
                                "product": {
                                    "@media (min-width: 601px)": {
                                        "max-width": "100%",
                                        "margin-left": "0px",
                                        "margin-bottom": "0px"
                                    }
                                },
                                "button": {
                                    "font-family": "Times New Roman, serif",
                                    ":hover": {
                                        "background-color": "#000000"
                                    },
                                    "background-color": "#000000",
                                    ":focus": {
                                        "background-color": "#000000"
                                    },
                                    "padding-left": "73px",
                                    "padding-right": "73px"
                                }
                            },
                            "text": {
                                "button": "Add to cart"
                            }
                        },
                        "cart": {
                            "styles": {
                                "button": {
                                    "font-family": "Times New Roman, serif",
                                    ":hover": {
                                        "background-color": "#000000"
                                    },
                                    "background-color": "#000000",
                                    ":focus": {
                                        "background-color": "#000000"
                                    }
                                }
                            },
                            "text": {
                                "total": "Subtotal",
                                "button": "Checkout"
                            }
                        },
                        "toggle": {
                            "styles": {
                                "toggle": {
                                    "font-family": "Times New Roman, serif",
                                    "background-color": "#000000",
                                    ":hover": {
                                        "background-color": "#000000"
                                    },
                                    ":focus": {
                                        "background-color": "#000000"
                                    }
                                }
                            }
                        }
                    },
                });
            });
        }
    }

    render() {
        return (
            <Row className={"content"}>
                <div id='collection-component-1605391327262'></div>
            </Row>
        );
    }
}

export default Merch;
