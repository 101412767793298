import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import {Image} from "react-bootstrap";
import houseOf99Logo from "../assets/images/sos_sound_logo.png"
import {Link} from "react-router-dom";


class Navigation extends Component {
    state = {};

    constructor(props) {
        super(props);
        console.log("constructor")
    }

    render() {
        return (
            <div className={"nav"}>
                <Link to={"/"}>
                    <Image className={"nav top-center logo"} src={houseOf99Logo}/>
                </Link>
                <Link to="/music">
                    <Button className={"nav top-left"}>Music</Button>
                </Link>
                <Link to="/roster">
                    <Button className={"nav top-right"}>Roster</Button>
                </Link>
                <Link to={"/submission"}>
                    <Button className={"nav bottom-center"}>Submission</Button>
                </Link>
                <Link to={"/merch"}>
                    <Button className={"nav bottom-left"}>Merch</Button>
                </Link>
                <a href={"https://www.instagram.com/Septemberfirm/"}>
                    <Button className={"nav bottom-right"}>Social</Button>
                </a>
            </div>
        );
    }
}

export default Navigation;
