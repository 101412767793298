import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home";
import React from "react";
import Navigation from "./components/Navigation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Music from "./components/Music";
import Roster from "./components/Roster";
import Merch from "./components/Merch";
import Submissions from "./components/Submissions";
import config from "./config.json"
import DocumentMeta from 'react-document-meta';
import Error404 from "./components/404";


function App() {
    const keywords = [];
    config.site_keywords.forEach((keyword) => {
        keywords.push(keyword.keyword)
    })
    const meta = {
        title: config.site_title,
        description: config.site_description,
        meta: {
            charset: 'utf-8',
            name: {
                keywords: keywords.join(",")
            }
        }
    };

    return (
        <Router>
            <DocumentMeta {...meta}>
                <div className="App">
                    <Navigation/>
                    <Switch>
                        <Route path="/" exact>
                            <Home/>
                        </Route>
                        <Route path="/music">
                            <Music/>
                        </Route>
                        <Route path="/roster">
                            <Roster/>
                        </Route>
                        <Route path="/merch">
                            <Merch/>
                        </Route>
                        <Route path="/submission">
                            <Submissions/>
                        </Route>
                        <Route path="/_error">
                            <Error404/>
                        </Route>
                    </Switch>
                </div>
            </DocumentMeta>
        </Router>
    );
}

export default App;
