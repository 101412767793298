import React, {Component} from "react";
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";

class Error404 extends Component {
    state = {};

    render()
    {
        return (
            <Row className={"content"}>
                <Col xs={12}>
                    <h1>Error 404</h1>
                    <Link to={"/"}>
                        <h6>Return Home</h6>
                    </Link>
                </Col>
            </Row>
        );
    }
}

export default Error404;
