import React, {Component} from "react";
import {Col, Form, Image} from "react-bootstrap";
import logo from "../assets/images/sos_sound_logo.png"
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

class Home extends Component {
    state = {};


    render() {
        return (
            <div className={"content"}>
                <Row>
                    <Col>
                        <Image src={logo} className={"album"}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={0}/>
                    <Col xl={3} lg={4} md={5} xs={12}>
                        <Form
                            action={""}
                            method={"POST"}>
                            <Form.Group>
                                <Form.Control size="sm" type="email" id="mce-EMAIL" name={"EMAIL"} placeholder="Enter your email"
                                              required/>
                            </Form.Group>
                            <div style={{position: "absolute", left: "-5000px"}} aria-hidden={true}>
                                <input type="text" name="b_8823f548326aa28d929d4c8ae_2eced8988f" tabindex="-1"
                                       value=""/>
                            </div>
                            <Button variant="link" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={0}/>
                </Row>
            </div>
        );
    }
}

export default Home;
