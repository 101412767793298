import React, {Component} from "react";
import {Col, Button, Form, Row} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "react-bootstrap/Alert";

const axios = require('axios');


class Submissions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                fname: {
                    valid: false,
                    value: ''
                },
                lname: {
                    valid: false,
                    value: ''
                },
                email: {
                    valid: false,
                    value: ''
                },
                file: {
                    valid: false,
                    value: null
                },
            },
            fileError: false,
            error: false,
            success: false
        }
        this.emptyForm = Object.assign({}, this.state.form);
        this.onChange = this.onChange.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.recaptchaRef = React.createRef();
    }

    validateEmail(e) {
        let emailPattern = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
        return emailPattern.test(e.target.value)
    };


    submitForm(e) {
        e.preventDefault()
        this.setState({error: false})
        if (!this.recaptchaRef.current.getValue()) {
            return this.setState({error: "Please complete the ReCAPTCHA"})
        }
        const formData = new FormData()
        formData.append("q3_name[first]", this.state.form.fname.value);
        formData.append("q3_name[last]", this.state.form.lname.value);
        formData.append("q4_email", this.state.form.email.value);
        formData.append("q5_fileUpload[]", this.state.form.file.value);
        formData.append('formID', '203294586939067');

        var config = {
            method: 'post',
            url: 'https://submit.jotform.com/submit/203294586939067/',
            headers: {
                'Content-Type': 'application/json',
            },
            data: formData
        };
        axios(config)
            .then(function (response) {
                var el = document.createElement('html');
                el.innerHTML = response.data
                let h1Tags = el.getElementsByTagName('h1')
                if (h1Tags[0].innerText === "Thank You!") {
                    this.setState({success: "Thank You! Your submission has be successfully sent.", form: this.emptyForm})
                }
            }.bind(this))
            .catch(function (error) {
                this.setState({error: "Could not send message! Please try again later."})
            });
    }

    onChange(e) {
        this.setState((prevState) => {
            let form = Object.assign({}, prevState.form);
            if (!form.hasOwnProperty(e.target.name)) {
                form[e.target.name] = {
                    valid: false,
                    value: null
                }
            }
            switch (e.target.name) {
                case "email":
                    form.email.valid = this.validateEmail(e);
                    break;
                default:
                    form[e.target.name].valid = e.target.value.length > 0;

            }
            form[e.target.name].value = e.target.value;
            return {form}
        })
    }

    onFileChange(e) {
        let file = e.target.files[0];
        if (file.type !== "audio/mpeg") {
            return this.setState({fileError: "Please upload audio files only!", file: null})
        }
        let formState = {...this.state.form}
        formState.file.value = e.target.files[0]
        this.setState({ form: formState });
    };

    render() {
        return (
            <React.Fragment>
                <Row className={"content"}>
                    <Col md={3}/>
                    <Col xs={12} md={6}>
                        <Form onSubmit={this.submitForm} data-netlify="true" data-netlify-recaptcha="true">
                            <Form.Group>
                                <Form.Label>Your Name:</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control name="fname" type="text" placeholder="First Name" onChange={this.onChange}
                                                      value={this.state.form.fname.value} required/>
                                    </Col>
                                    <Col>
                                        <Form.Control inline name="lname" type="text" placeholder="Last Name" onChange={this.onChange}
                                                      value={this.state.form.lname.value} required/>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="email" type="email" placeholder="Enter email"
                                              onChange={this.onChange} value={this.state.form.email.value} required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.File id="exampleFormControlFile1" label={this.state.form.file.value ? (this.state.form.file.value.name.length > 30 ? this.state.form.file.value.name.slice(0,30)+ "..." : this.state.form.file.value.name) : "Upload mp3"} onChange={this.onFileChange} required custom/>
                                {this.state.fileError ? <Alert severity="error">{this.state.fileError}</Alert> : null}
                            </Form.Group>
                            <Form.Control type={"hidden"} name={"form-name"} value={"Submission_Page_Form"}/>
                            <ReCAPTCHA
                                ref={this.recaptchaRef}
                                size="normal"
                                sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY}
                                onErrored={() => {
                                    this.setState({error: "Could not send message! reCaptcha Failed"})
                                }}
                            />
                            <Button variant="primary" type="submit" style={{margin: "15px"}}>
                                Submit
                            </Button>
                            <Col xs={12} hidden={!this.state.error} style={{margin: "15px"}}>
                                {(this.state.error) ? <Alert variant="danger">{this.state.error}</Alert> : null}
                            </Col>
                            <Col xs={12} hidden={!this.state.success} style={{margin: "15px"}}>
                                {(this.state.success) ?
                                    <Alert variant="success">{this.state.success}</Alert> : null}
                            </Col>
                        </Form>
                    </Col>
                    <Col md={3}/>
                </Row>
            </React.Fragment>
        );
    }
}

export default Submissions;
